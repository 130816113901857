import flatpickr from 'flatpickr';
import FlatpickrLanguages from 'flatpickr/dist/l10n';


$(document).ready(() => {
  flatpickr('.flatpickr', {
    locale: { ...FlatpickrLanguages.zh_tw },
    format: 'LT',
    maxDate: "today"
  });

  flatpickr('.flatpickr-future', {
    locale: { ...FlatpickrLanguages.zh_tw },
    format: 'LT',
  });

  flatpickr('.time-picker', {
    locale: { ...FlatpickrLanguages.zh_tw },
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    time_24hr: true
  });
});
