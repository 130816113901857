import '../src/jquery';
import 'jquery-ui';

import 'bootstrap';
import 'jquery-toast-plugin';

import 'perfect-scrollbar/jquery';
import 'jquery-sparkline';

import 'moment';
import '@nathanvda/cocoon';

import '../src/pdfMake';
import 'datatables.net-bs5'

import '../src/eliteadmin/index';

import 'dropify';
import '../src/dropzone';

import '../src/flatpickr';

import 'magnific-popup';

import Rails from "@rails/ujs";

// 啟動 rails/ujs
Rails.start();
